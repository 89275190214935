.asideMenu {
    background-color: #61707D;
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menuItems > li {
    width: 100%;
    color: white;
}

.menuItem:hover {
    background-color: #505D68 !important;
}